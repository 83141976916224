.no-video {
    background: var(--first-color);
}

.video-chat-error {
    margin: 3rem;

    text-align: center;
}

.local-video-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;
}

.hidden-local-video-toolbar {
    width: 24px;
    height: 24px;

    padding: 0.5em;
    margin: 0.5em;
}

.local-video-toolbar {
    margin-top: 0.5em;
}

.local-video-toolbar-item {
    background-color: var(--text-color);
    border-color: var(--text-color-gray);
    border-width: 1px;
    color: var(--text-color-white);

    border-radius: 4px;

    width: 24px;
    height: 24px;

    padding: 0.5em;
    margin: 0.5em;

    cursor: pointer;
}

.local-video-toolbar-item:hover {
    color: #e52929;
}

.local-video {
    border-radius: var(--border-radius);

    max-width: 240px;
    max-height: 180px;
    box-shadow: 1px 1px 4px var(--text-color);
    margin: 1em;
}

.remote-video-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;
}

.remote-video-box {
    position: relative;

    overflow: hidden;
}

.remote-video {
    border-radius: var(--border-radius);

    max-width: 352px;
    max-height: 288px;
    box-shadow: 1px 1px 4px var(--text-color);
    margin: 0.15em 0.15em 0.15em 0.15em;
}

.remote-video-label {
    border-radius: var(--border-radius);
    position: absolute;
    bottom: 1em;
    left: 0.5em;
    padding: 0.3em;
    color: var(--text-color-white);
    background-color: var(--text-color);
    opacity: 0.7;
    z-index: 1;
}

.remote-video-muted-indicator {
    position: absolute;
    bottom: 1em;
    right: 1em;
    z-index: 1;
    padding: 0.5em;
    opacity: 0.8;

    background-color: var(--text-color);
    color: var(--text-color-white);
}
