h2 {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 140%;
    color: var(--text-color);
}

.room-link {
    display: flex;
    padding: 0.25em;
    border: 1px solid var(--text-color);
    margin: 0.5em;
    background: var(--text-color-white);
    border-radius: var(--border-radius);
    text-decoration: none;
    color: var(--text-color);
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}

@media only screen and (min-width: 64em) {
    .room-link {
        padding: 0.5em;
        margin: 3em;
    }
}

.room-list {
    position: relative;
    top: 0;
    left: 0;
}

.room-background {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -1;
}
