.chat-wrapper {
    background: var(--text-color-white);
    padding: 0.3em;
    display: grid;
    grid-template-areas:
    'header header header header header'
    'chat chat chat chat chat'
    'chat chat chat chat chat'
    'input input input input input';
}

.chat-room-header {
    position: relative;
    grid-area: header;
    width: 100%;
    height: 1.2em;

    text-align: left;
    line-height: 1.2em;
    font-size: 0.8em;
    color: var(--text-color-gray);

    padding-bottom: 0.4em;
    border-bottom: 1px dotted #acacac;
}

.chat-room-header b {
    font-weight: bold;
}

.chat-room-header .material-icons {
    float: right;
    margin-left: 5px;
    font-size: 18px;
}

.chat-room-header .material-icons:hover {
    color: #e52929;
    cursor: pointer;
}

.chat-room {
    grid-area: chat;

    background: var(--text-color-white);
    min-height: 10em;
    height: 10em;
    overflow: auto;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.chat-room-message-wrapper:nth-child(even) {
    background: var(--background-light-grey);
}

.chat-room-message-wrapper {
    display: flex;
    margin-bottom: 0.2em;
    padding: 0.3rem;
}

.chat-room-message-wrapper.system {
    background: var(--second-color);
}

.chat-room-time {
    font-size: 70%;
    font-weight: normal;
    line-height: 142%;
    padding-top: 0.1rem;
    padding-right: 0.3rem;
}

.chat-room-user {
    text-align: right;
    font-weight: bold;
}

.chat-room-message {
    padding-left: 0.3rem;
}

.message-box {
    grid-area: input;
}

.message-box input {
    border-radius: 0;
}

.message-box input:focus {
    outline: none;
}

.chat-room-user-list {
    grid-area: user-list;
    padding: 0.5em;

    overflow: scroll;
}

.chat-room-user-list ul {
    padding-top: 1em;
    max-height: 10em;
}

.chat-room-user-list ul > li {
    padding: 0.5rem;
    cursor: pointer;
}

.chat-room-user-list ul > li:hover {
    background: var(--background-light-grey);
}

.chat-active-user-list {
    font-weight: bold;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.chat-inactive-user-list {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
}

.chat-inactive-user-list:hover {
    background: var(--background-light-grey);
}
