iframe {
    overflow: hidden;
}

.VRAMEContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.game-canvas {
    width: 100%;
}

.unityprogress {

}

progress {
    width: 100%;
    height: 1em;
    border-radius: var(--border-radius);
    border: 0;
    background: var(--text-color-white);

    margin: 1em;
}

progress::-webkit-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-webkit-progress-value {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-moz-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;

    /* Dimensions */
    width: 100%;
    height: 1em;
}

progress::-webkit-progress-value {
    background: var(--text-color);
    border-radius: var(--border-radius);
}

progress::-moz-progress-bar {
    background: var(--text-color);
    border-radius: var(--border-radius);
}

.hidden {
    visibility: hidden;
    opacity: 100%;
}

.unsupported {
    margin-top: 3em;
    margin-bottom: 3em;
    color: #ffff8c;
}
