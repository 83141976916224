@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
    --background-white: #6cb7b5;
    --background-light-grey: #ececec;
    --border-grey: #c8c8c8;
    --text-color: #333;
    --text-color-gray: #6a6a6a;
    --text-color-white: #fff;
    --border-radius: 5px;

    --first-color: #3c771b;
    --second-color: #ffff8c;
}

html {
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

body {
    background: url('../img/background.jpg') no-repeat fixed center;
    background-size: cover;
    color: var(--text-color);
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

.login-page {
    width: 100%;
    height: 100%;
    background: url('../img/background.jpg') no-repeat fixed center;
    background-size: cover;
}

.m-t-1em {
    margin-top: 1em;
}
.m-t-2em {
    margin-top: 2em;
}
.m-t-3em {
    margin-top: 3em;
}
.m-t-4em {
    margin-top: 4em;
}
.m-t-6em {
    margin-top: 6em;
}
.m-t-8em {
    margin-top: 8em;
}

@media only screen and (min-width: 48em) {
    .m-sm-t-2em {
        margin-top: 2em;
    }
    .m-sm-t-3em {
        margin-top: 3em;
    }
    .m-sm-t-4em {
        margin-top: 4em;
    }
    .m-sm-t-6em {
        margin-top: 6em;
    }
    .m-sm-t-8em {
        margin-top: 8em;
    }
    .m-sm-t-10em {
        margin-top: 10em;
    }
    .m-sm-t-12em {
        margin-top: 12em;
    }
}

@media only screen and (min-width: 64em) {
    .m-md-t-2em {
        margin-top: 2em;
    }
    .m-md-t-3em {
        margin-top: 3em;
    }
    .m-md-t-4em {
        margin-top: 4em;
    }
    .m-md-t-6em {
        margin-top: 6em;
    }
    .m-md-t-8em {
        margin-top: 8em;
    }
    .m-md-t-10em {
        margin-top: 10em;
    }
    .m-md-t-12em {
        margin-top: 12em;
    }
}

.login-form {
    background: var(--text-color-white);
    border: 1px solid var(--border-grey);
    border-radius: var(--border-radius);
    padding: 1em;

    transition:opacity 1s;
}

.login-form-hidden {
    visibility: hidden;
    opacity: 0;
}

.login-form h3 {
    margin-bottom: 1em;
}

main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.middle {
    height: 100%;
    justify-content: center;
}

input {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: none;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid var(--border-grey);
    box-sizing: border-box;
}

button {
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--text-color);
    color: var(--text-color-white);
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 1em;
}

button:disabled {
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--border-grey);
    color: var(--text-color-white);
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 1em;
}

h1, h2, h3 {
    font-weight: bold;
}

.navigation-button {
    border-radius: var(--border-radius);
    background: var(--text-color);
    color: var(--text-color-white);
    text-decoration: none;
    padding: 0.5em 1em;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: bold;
    margin-top: 1em;
}

.error p {
    font-size: 65%;
    color: darkred;
    margin-top: 0.3em;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
}

.background-tutorial {
    margin-top: 4em;
}

#footer-chat {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;

    background: var(--text-color-white);
    color: var(--text-color);

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

#footer-chat .container {
    position: relative;
}

.hidden {
    visibility: hidden;
}

.badge {
    background-color: #e52929;
    border-radius: 45%;
    color: var(--text-color-white);
    margin-top: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 85%;
    font-weight: bolder;
    text-align: center;
    line-height: 1.2em;
    min-width: 0.75em;
}

.badge-gray {
    background-color: #acacac;
}

.badge-green {
    background-color: #62b036;
}

.badge-right {
    float: right;
    margin-right: 5px;
}

.badge-overlap {
    margin-left: -8px;
    font-size: 75%;
    vertical-align: super;
}

.chat-dock {
    display: inline-block;
    width: 240px;
    line-height: 2.5em;
    cursor: pointer;
    color: var(--text-color);
    font-size: 80%;
    font-weight: bold;
}

.chat-dock:hover {
    color: #e52929;
}

.chat-dock .material-icons {
    margin-left: 5px;
    margin-right: 2px;
    font-size: 18px;
    vertical-align: middle;
    color: var(--text-color);
}

.chat-wrapper {
    position: absolute;
    bottom: 100%;
    left: 1%;
    width: calc(97%);

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24);
    z-index: 11;
}

.active {
    background-color: yellow;
    cursor: pointer;
}

.user-list {
    position: relative;
    display: inline-block;
    width: 240px;

    line-height: 2.5em;
    font-size: 0.8em;
    font-weight: bold;
}

.user-list ul {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: -20px;

    width: 280px;

    background-color: var(--text-color-white);
    color: var(--text-color);
    line-height: 1.5em;
    font-size: 1.2em;
    font-weight: normal;
    text-align: left;

    max-height: 12em;
    min-height: 7em;
    overflow-y: auto;
    overflow-x: hidden;

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24);
    z-index: 12;
}

.user-list ul li {
    padding: 5px;
}

.user-list .material-icons {
    margin-left: 5px;
    font-size: 18px;
    vertical-align: middle;
}

.user-list .right {
    float: right;
    margin-right: 5px;
    margin-top: 0.2em;
    cursor: pointer;
    font-size: 18px;
}

.user-list .right:hover {
    color: #e52929;
}

.user-list .disabled:hover {
    color: var(--text-color);
    background-color: var(--text-color-white);
    cursor: no-drop;
}

.user-list:hover ul {
    visibility: visible;
}

.user-list ul li:hover {
    background-color: #eaeaea;
}

.whispers ul li {
    display: block;
    cursor: pointer;
}

.whispers ul li:hover {
    color: #e52929;
}
