@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
:root {
    --background-white: #6cb7b5;
    --background-light-grey: #ececec;
    --border-grey: #c8c8c8;
    --text-color: #333;
    --text-color-gray: #6a6a6a;
    --text-color-white: #fff;
    --border-radius: 5px;

    --first-color: #3c771b;
    --second-color: #ffff8c;
}

html {
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

body {
    background: url(/static/media/background.2d07bd16.jpg) no-repeat fixed center;
    background-size: cover;
    color: #333;
    color: var(--text-color);
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

.login-page {
    width: 100%;
    height: 100%;
    background: url(/static/media/background.2d07bd16.jpg) no-repeat fixed center;
    background-size: cover;
}

.m-t-1em {
    margin-top: 1em;
}
.m-t-2em {
    margin-top: 2em;
}
.m-t-3em {
    margin-top: 3em;
}
.m-t-4em {
    margin-top: 4em;
}
.m-t-6em {
    margin-top: 6em;
}
.m-t-8em {
    margin-top: 8em;
}

@media only screen and (min-width: 48em) {
    .m-sm-t-2em {
        margin-top: 2em;
    }
    .m-sm-t-3em {
        margin-top: 3em;
    }
    .m-sm-t-4em {
        margin-top: 4em;
    }
    .m-sm-t-6em {
        margin-top: 6em;
    }
    .m-sm-t-8em {
        margin-top: 8em;
    }
    .m-sm-t-10em {
        margin-top: 10em;
    }
    .m-sm-t-12em {
        margin-top: 12em;
    }
}

@media only screen and (min-width: 64em) {
    .m-md-t-2em {
        margin-top: 2em;
    }
    .m-md-t-3em {
        margin-top: 3em;
    }
    .m-md-t-4em {
        margin-top: 4em;
    }
    .m-md-t-6em {
        margin-top: 6em;
    }
    .m-md-t-8em {
        margin-top: 8em;
    }
    .m-md-t-10em {
        margin-top: 10em;
    }
    .m-md-t-12em {
        margin-top: 12em;
    }
}

.login-form {
    background: #fff;
    background: var(--text-color-white);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--border-grey);
    border-radius: 5px;
    border-radius: var(--border-radius);
    padding: 1em;

    transition:opacity 1s;
}

.login-form-hidden {
    visibility: hidden;
    opacity: 0;
}

.login-form h3 {
    margin-bottom: 1em;
}

main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.middle {
    height: 100%;
    justify-content: center;
}

input {
    width: 100%;
    border-radius: 5px;
    border-radius: var(--border-radius);
    box-shadow: none;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid #c8c8c8;
    border: 1px solid var(--border-grey);
    box-sizing: border-box;
}

button {
    width: 100%;
    border-radius: 5px;
    border-radius: var(--border-radius);
    background: #333;
    background: var(--text-color);
    color: #fff;
    color: var(--text-color-white);
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 1em;
}

button:disabled {
    width: 100%;
    border-radius: 5px;
    border-radius: var(--border-radius);
    background: #c8c8c8;
    background: var(--border-grey);
    color: #fff;
    color: var(--text-color-white);
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 1em;
}

h1, h2, h3 {
    font-weight: bold;
}

.navigation-button {
    border-radius: 5px;
    border-radius: var(--border-radius);
    background: #333;
    background: var(--text-color);
    color: #fff;
    color: var(--text-color-white);
    text-decoration: none;
    padding: 0.5em 1em;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: bold;
    margin-top: 1em;
}

.error p {
    font-size: 65%;
    color: darkred;
    margin-top: 0.3em;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
}

.background-tutorial {
    margin-top: 4em;
}

#footer-chat {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;

    background: #fff;

    background: var(--text-color-white);
    color: #333;
    color: var(--text-color);

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

#footer-chat .container {
    position: relative;
}

.hidden {
    visibility: hidden;
}

.badge {
    background-color: #e52929;
    border-radius: 45%;
    color: #fff;
    color: var(--text-color-white);
    margin-top: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 85%;
    font-weight: bolder;
    text-align: center;
    line-height: 1.2em;
    min-width: 0.75em;
}

.badge-gray {
    background-color: #acacac;
}

.badge-green {
    background-color: #62b036;
}

.badge-right {
    float: right;
    margin-right: 5px;
}

.badge-overlap {
    margin-left: -8px;
    font-size: 75%;
    vertical-align: super;
}

.chat-dock {
    display: inline-block;
    width: 240px;
    line-height: 2.5em;
    cursor: pointer;
    color: #333;
    color: var(--text-color);
    font-size: 80%;
    font-weight: bold;
}

.chat-dock:hover {
    color: #e52929;
}

.chat-dock .material-icons {
    margin-left: 5px;
    margin-right: 2px;
    font-size: 18px;
    vertical-align: middle;
    color: #333;
    color: var(--text-color);
}

.chat-wrapper {
    position: absolute;
    bottom: 100%;
    left: 1%;
    width: calc(97%);

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24);
    z-index: 11;
}

.active {
    background-color: yellow;
    cursor: pointer;
}

.user-list {
    position: relative;
    display: inline-block;
    width: 240px;

    line-height: 2.5em;
    font-size: 0.8em;
    font-weight: bold;
}

.user-list ul {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: -20px;

    width: 280px;

    background-color: #fff;

    background-color: var(--text-color-white);
    color: #333;
    color: var(--text-color);
    line-height: 1.5em;
    font-size: 1.2em;
    font-weight: normal;
    text-align: left;

    max-height: 12em;
    min-height: 7em;
    overflow-y: auto;
    overflow-x: hidden;

    box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24);
    z-index: 12;
}

.user-list ul li {
    padding: 5px;
}

.user-list .material-icons {
    margin-left: 5px;
    font-size: 18px;
    vertical-align: middle;
}

.user-list .right {
    float: right;
    margin-right: 5px;
    margin-top: 0.2em;
    cursor: pointer;
    font-size: 18px;
}

.user-list .right:hover {
    color: #e52929;
}

.user-list .disabled:hover {
    color: #333;
    color: var(--text-color);
    background-color: #fff;
    background-color: var(--text-color-white);
    cursor: no-drop;
}

.user-list:hover ul {
    visibility: visible;
}

.user-list ul li:hover {
    background-color: #eaeaea;
}

.whispers ul li {
    display: block;
    cursor: pointer;
}

.whispers ul li:hover {
    color: #e52929;
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.show-sm {
  visibility: hidden;
}
.show-md {
  visibility: hidden;
}
.show-lg {
  visibility: hidden;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .hide-sm {
    visibility: hidden;
    display: none;
  }
  .show-sm {
    visibility: visible;
  }
  .show-md {
    visibility: hidden;
  }
  .show-lg {
    visibility: hidden;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .hide-md {
    visibility: hidden;
    display: none;
  }
  .show-md {
    visibility: visible;
  }
  .show-lg {
    visibility: hidden;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .hide-lg {
    visibility: hidden;
    display: none;
  }
  .show-lg {
    visibility: visible;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
.header {
    background: var(--text-color-white);
    color: var(--text-color);
    padding: 1em 1em 1em 40px;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 20px;
    height: 16px;
    left: 10px;
    top: 16px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: var(--text-color);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #dc1d37;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: var(--text-color-white);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    text-transform: none;
    color: var(--text-color);
    margin-bottom: 0.6em;
    text-decoration: none;
    border: none;
    outline: none;
}

.bm-item:hover {
    text-decoration: underline;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
}

h2 {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 140%;
    color: var(--text-color);
}

.room-link {
    display: flex;
    padding: 0.25em;
    border: 1px solid var(--text-color);
    margin: 0.5em;
    background: var(--text-color-white);
    border-radius: var(--border-radius);
    text-decoration: none;
    color: var(--text-color);
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}

@media only screen and (min-width: 64em) {
    .room-link {
        padding: 0.5em;
        margin: 3em;
    }
}

.room-list {
    position: relative;
    top: 0;
    left: 0;
}

.room-background {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -1;
}

iframe {
    overflow: hidden;
}

.VRAMEContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.game-canvas {
    width: 100%;
}

.unityprogress {

}

progress {
    width: 100%;
    height: 1em;
    border-radius: var(--border-radius);
    border: 0;
    background: var(--text-color-white);

    margin: 1em;
}

progress::-webkit-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-webkit-progress-value {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-moz-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;

    /* Dimensions */
    width: 100%;
    height: 1em;
}

progress::-webkit-progress-value {
    background: var(--text-color);
    border-radius: var(--border-radius);
}

progress::-moz-progress-bar {
    background: var(--text-color);
    border-radius: var(--border-radius);
}

.hidden {
    visibility: hidden;
    opacity: 100%;
}

.unsupported {
    margin-top: 3em;
    margin-bottom: 3em;
    color: #ffff8c;
}

.no-video {
    background: var(--first-color);
}

.video-chat-error {
    margin: 3rem;

    text-align: center;
}

.local-video-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;
}

.hidden-local-video-toolbar {
    width: 24px;
    height: 24px;

    padding: 0.5em;
    margin: 0.5em;
}

.local-video-toolbar {
    margin-top: 0.5em;
}

.local-video-toolbar-item {
    background-color: var(--text-color);
    border-color: var(--text-color-gray);
    border-width: 1px;
    color: var(--text-color-white);

    border-radius: 4px;

    width: 24px;
    height: 24px;

    padding: 0.5em;
    margin: 0.5em;

    cursor: pointer;
}

.local-video-toolbar-item:hover {
    color: #e52929;
}

.local-video {
    border-radius: var(--border-radius);

    max-width: 240px;
    max-height: 180px;
    box-shadow: 1px 1px 4px var(--text-color);
    margin: 1em;
}

.remote-video-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;
}

.remote-video-box {
    position: relative;

    overflow: hidden;
}

.remote-video {
    border-radius: var(--border-radius);

    max-width: 352px;
    max-height: 288px;
    box-shadow: 1px 1px 4px var(--text-color);
    margin: 0.15em 0.15em 0.15em 0.15em;
}

.remote-video-label {
    border-radius: var(--border-radius);
    position: absolute;
    bottom: 1em;
    left: 0.5em;
    padding: 0.3em;
    color: var(--text-color-white);
    background-color: var(--text-color);
    opacity: 0.7;
    z-index: 1;
}

.remote-video-muted-indicator {
    position: absolute;
    bottom: 1em;
    right: 1em;
    z-index: 1;
    padding: 0.5em;
    opacity: 0.8;

    background-color: var(--text-color);
    color: var(--text-color-white);
}

.chat-wrapper {
    background: var(--text-color-white);
    padding: 0.3em;
    display: grid;
    grid-template-areas:
    'header header header header header'
    'chat chat chat chat chat'
    'chat chat chat chat chat'
    'input input input input input';
}

.chat-room-header {
    position: relative;
    grid-area: header;
    width: 100%;
    height: 1.2em;

    text-align: left;
    line-height: 1.2em;
    font-size: 0.8em;
    color: var(--text-color-gray);

    padding-bottom: 0.4em;
    border-bottom: 1px dotted #acacac;
}

.chat-room-header b {
    font-weight: bold;
}

.chat-room-header .material-icons {
    float: right;
    margin-left: 5px;
    font-size: 18px;
}

.chat-room-header .material-icons:hover {
    color: #e52929;
    cursor: pointer;
}

.chat-room {
    grid-area: chat;

    background: var(--text-color-white);
    min-height: 10em;
    height: 10em;
    overflow: auto;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.chat-room-message-wrapper:nth-child(even) {
    background: var(--background-light-grey);
}

.chat-room-message-wrapper {
    display: flex;
    margin-bottom: 0.2em;
    padding: 0.3rem;
}

.chat-room-message-wrapper.system {
    background: var(--second-color);
}

.chat-room-time {
    font-size: 70%;
    font-weight: normal;
    line-height: 142%;
    padding-top: 0.1rem;
    padding-right: 0.3rem;
}

.chat-room-user {
    text-align: right;
    font-weight: bold;
}

.chat-room-message {
    padding-left: 0.3rem;
}

.message-box {
    grid-area: input;
}

.message-box input {
    border-radius: 0;
}

.message-box input:focus {
    outline: none;
}

.chat-room-user-list {
    grid-area: user-list;
    padding: 0.5em;

    overflow: scroll;
}

.chat-room-user-list ul {
    padding-top: 1em;
    max-height: 10em;
}

.chat-room-user-list ul > li {
    padding: 0.5rem;
    cursor: pointer;
}

.chat-room-user-list ul > li:hover {
    background: var(--background-light-grey);
}

.chat-active-user-list {
    font-weight: bold;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.chat-inactive-user-list {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
}

.chat-inactive-user-list:hover {
    background: var(--background-light-grey);
}

