.header {
    background: var(--text-color-white);
    color: var(--text-color);
    padding: 1em 1em 1em 40px;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 20px;
    height: 16px;
    left: 10px;
    top: 16px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: var(--text-color);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #dc1d37;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: var(--text-color-white);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    text-transform: none;
    color: var(--text-color);
    margin-bottom: 0.6em;
    text-decoration: none;
    border: none;
    outline: none;
}

.bm-item:hover {
    text-decoration: underline;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
}
